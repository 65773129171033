/**
 * @description
 * The date format used to display date to users.
 */
export const DISPLAY_DATE_FORMAT = 'DD-MM-YYYY';
/**
 * @description
 * The date format BE usually expects.
 */
export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const DISPLAY_DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const DATE_DAY_UNIT_KEY = 'day';

export const TIME_FORMAT = 'HH:mm';

export const DAY_MONTH_FORMAT = 'DD MMMM';

export const DAY_FORMAT = 'DD';

export const WEEKDAY_DATE_FORMAT = 'dddd DD MMMM';

export const DATE_ZULU_FORMAT = 'YYYY-MM-DDTHH:mm';

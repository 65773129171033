import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { track } from '@bootstrap/utils/mixpanel';
import MixpanelEventName from '@factoring/configs/mixpanel';
import { useInvoiceStatusOptions } from '@factoring/modules/invoice/Invoice.constants';
import { useBranchForInvoiceFilterQuery } from '@factoring/types/generated.hooks';
import { ReactComponent as Tune } from '@ui/assets/icons/tune.svg';
import { Button } from '@ui/button';
import { ChoiceChipGroup, IChoiceChipOption } from '@ui/choice-chip';
import { Drawer } from '@ui/drawer';
import { FilterChipsGroup } from '@ui/filter-chip';
import { Flex } from '@ui/flex';
import { Space } from '@ui/space';

import { useFilterKeysTranslations } from './InvoicesTable.hooks';
import { useInvoicesTableFilterContext } from './InvoicesTableFilters.context';
import { InvoicesFilterField, InvoicesTableFiltersValues } from './InvoicesTableFilters.types';
import { InvoicesTableFiltersDrawer } from './InvoicesTableFiltersDrawer';

const filterKeys: string[] = [
  InvoicesFilterField.INVOICE_NUMBER,
  InvoicesFilterField.ISSUED_TO_BRANCH_ID,
  InvoicesFilterField.ISSUE_DATE_FROM,
  InvoicesFilterField.ISSUE_DATE_TO,
  InvoicesFilterField.OUTSTANDING_FROM,
  InvoicesFilterField.OUTSTANDING_TO,
  InvoicesFilterField.RECEIVABLE_FROM,
  InvoicesFilterField.RECEIVABLE_TO,
];

interface IInvoicesTableFiltersProps {
  currency?: Currency;
}

export const InvoicesTableFilters = ({ currency = 'EUR' }: IInvoicesTableFiltersProps) => {
  const { searchParams, setSearchParams, filters } = useInvoicesTableFilterContext();
  const [isOpen, setIsOpen] = useState(false);
  const searchParamsArray = Array.from(searchParams.entries()).filter(([key]) => filterKeys.includes(key));

  const { data } = useBranchForInvoiceFilterQuery({
    skip: !filters.issuedToBranchId,
    variables: {
      branchId: filters.issuedToBranchId ?? '',
    },
  });
  const branchName = data?.branch.name ?? '';
  const filterKeysTranslations = useFilterKeysTranslations(branchName);

  const onChoiceChipChanged = (option: IChoiceChipOption) => {
    searchParams.set(InvoicesFilterField.STATUS, option.value);
    setSearchParams(searchParams);
  };

  const invoiceStatus = searchParams.get(InvoicesFilterField.STATUS) as InvoiceStatus | undefined;
  const statusOptions = useInvoiceStatusOptions();

  const values: InvoicesTableFiltersValues = {
    [InvoicesFilterField.STATUS]: statusOptions.find((option) => option.value === invoiceStatus) ?? null,
    [InvoicesFilterField.INVOICE_NUMBER]: filters.invoiceNumber ?? '',
    [InvoicesFilterField.ISSUED_TO_BRANCH_ID]: filters.issuedToBranchId
      ? { value: filters.issuedToBranchId, label: branchName }
      : null,
    [InvoicesFilterField.ISSUE_DATE_FROM]: filters.issueDate?.startDate ?? null,
    [InvoicesFilterField.ISSUE_DATE_TO]: filters.issueDate?.endDate ?? null,
    [InvoicesFilterField.OUTSTANDING_FROM]: '', //TODO: Add when implemented in API
    [InvoicesFilterField.OUTSTANDING_TO]: '', //TODO: Add when implemented in API
    [InvoicesFilterField.RECEIVABLE_FROM]: '', //TODO: Add when implemented in API
    [InvoicesFilterField.RECEIVABLE_TO]: '', //TODO: Add when implemented in API
  };

  const methods = useForm<InvoicesTableFiltersValues>({
    defaultValues: values,
    values,
  });

  return (
    <>
      <Flex justify="space-between" align="center" gap={8}>
        <ChoiceChipGroup options={statusOptions} onChange={onChoiceChipChanged} value={invoiceStatus ?? 'ALL'} />
        <Button
          iconLeft={<Tune />}
          variant="secondary"
          onClick={() => {
            track(MixpanelEventName.FACTORING_INVOICES_FILTERS);
            setIsOpen(true);
          }}
        >
          <FormattedMessage id="action.filter" />
        </Button>
      </Flex>
      {searchParamsArray.length > 0 && <Space height={16} />}
      <FilterChipsGroup
        filters={searchParamsArray.map(([key, value]) => {
          return {
            id: key,
            label: filterKeysTranslations[key as InvoicesFilterField](value),
            onDelete: () => {
              searchParams.delete(key);
              setSearchParams(searchParams);
              methods.resetField(key as InvoicesFilterField);
            },
          };
        })}
        onClearAll={() => {
          methods.reset();
          setSearchParams({});
        }}
      />
      <Drawer
        isOpen={isOpen}
        size="s"
        onClose={() => setIsOpen(!isOpen)}
        title={<FormattedMessage id="action.filter" />}
      >
        <InvoicesTableFiltersDrawer onClose={() => setIsOpen(false)} currency={currency} {...methods} />
      </Drawer>
    </>
  );
};

import { FormattedMessage } from 'react-intl';

import { useInvoicesTableColumns } from '@factoring/modules/invoice/invoices-table';
import { TableErrorCard } from '@factoring/shared/components/table-error-card';
import { invoicesOrderingFieldMapping } from '@factoring/shared/constants/invoicesOrderingFieldMapping';
import { InvoiceStatus as InvoiceStatusEnum } from '@factoring/types/generated.enum';
import { useInvoicesQuery } from '@factoring/types/generated.hooks';
import { ColorNeutral800, ShadowDefault } from '@hub/design-tokens';
import { Paper } from '@ui/paper';
import { SortDirection, Table } from '@ui/table';
import { TableTop } from '@ui/table/TableAddons';
import { Typography, TypographyType } from '@ui/typography';

export const DashboardRecentInvoicesTable = () => {
  const columns = useInvoicesTableColumns({ isSortable: false });

  const { data, error, loading, refetch } = useInvoicesQuery({
    variables: {
      filters: {
        invoiceType: 'MERCHANT_DEBTOR',
        statuses: [InvoiceStatusEnum.Draft, InvoiceStatusEnum.Submitted],
      },
      pagination: { offset: 0, limit: 5 },
      ordering: {
        field: invoicesOrderingFieldMapping.created,
        direction: SortDirection.DESC,
      },
    },
  });

  return (
    <Paper boxShadow={ShadowDefault}>
      <TableTop
        title={
          <Typography type={TypographyType.HEADING_S} color={ColorNeutral800}>
            <FormattedMessage id="dashboard.recentInvoices" />
          </Typography>
        }
      />
      <Table
        loadingHeight="200px"
        isError={!!error}
        isLoading={loading}
        data={(data?.invoices.items ?? []) as Invoice[]}
        columns={columns}
        keyField="uuid"
        errorComponent={<TableErrorCard titleKey="error.oops" refetch={refetch} />}
      />
    </Paper>
  );
};

import styled from 'styled-components';

import { IStatusProps } from './Status.types';

const backgrounds = {
  neutral: 'var(--color-neutral-200)',
  informative: 'var(--color-primary-200)',
  success: 'var(--color-positive-200)',
  warning: 'var(--color-warning-200)',
  error: 'var(--color-negative-200)',
  // Additional status color schema types according to the business
  black: 'var(--color-black)',
  yellow: '#F2FF91', // TODO: Add color to tokens properly
};

const svgBackgraounds = {
  neutral: 'var(--color-neutral-700)',
  informative: 'var(--color-primary-700)',
  success: 'var(--color-positive-700)',
  warning: 'var(--color-warning-700)',
  error: 'var(--color-negative-700)',
  // Additional status color schema types according to the business
  black: 'var(--color-white)',
  yellow: 'var(--color-positive-700)',
};

export const StatusStyles = styled.div<Pick<IStatusProps, 'type'>>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: var(--border-radius-default);
  background: ${({ type = 'neutral' }) => backgrounds[type]};

  svg {
    margin-right: 4px;
    width: 20px;
    height: 20px;
    color: ${({ type = 'neutral' }) => svgBackgraounds[type]};
  }
`;

import { createContext, ReactNode, useContext, useEffect } from 'react';

import { IUseSearchParamsManager, useFormFilterManager } from '@bootstrap/hooks/useFormFilterManager';
import { InvoiceType } from '@factoring/types/generated.enum';

import { InvoicesFilterField } from './InvoicesTableFilters.types';

export interface IInvoicesTableFiltersContextProps extends IUseSearchParamsManager {
  filters: InvoiceFilters;
}

const InvoicesTableFiltersContext = createContext<IInvoicesTableFiltersContextProps>(
  {} as IInvoicesTableFiltersContextProps,
);

export const InvoicesTableFiltersContextProvider = ({ children }: { children: ReactNode }) => {
  const formFilterManagersMethods = useFormFilterManager({
    persistKey: `factoring${window.location.pathname}`,
  });
  const { getSearchParam, setSearchParams } = formFilterManagersMethods;

  const searchParamsObject = {
    [InvoicesFilterField.STATUS]: getSearchParam(InvoicesFilterField.STATUS),
    [InvoicesFilterField.ISSUE_DATE_FROM]: getSearchParam(InvoicesFilterField.ISSUE_DATE_FROM),
    [InvoicesFilterField.ISSUE_DATE_TO]: getSearchParam(InvoicesFilterField.ISSUE_DATE_TO),
    [InvoicesFilterField.ISSUED_TO_BRANCH_ID]: getSearchParam(InvoicesFilterField.ISSUED_TO_BRANCH_ID),
    [InvoicesFilterField.INVOICE_NUMBER]: getSearchParam(InvoicesFilterField.INVOICE_NUMBER),
    offset: getSearchParam('offset'),
  };

  const status = searchParamsObject[InvoicesFilterField.STATUS];

  const filters: InvoiceFilters = {
    invoiceType: InvoiceType.MerchantDebtor,
    status: status === 'ALL' ? null : (status as InvoiceStatus),
    invoiceNumber: searchParamsObject[InvoicesFilterField.INVOICE_NUMBER],
    issuedToBranchId: searchParamsObject[InvoicesFilterField.ISSUED_TO_BRANCH_ID],
    issueDate: {
      startDate: searchParamsObject[InvoicesFilterField.ISSUE_DATE_FROM],
      endDate: searchParamsObject[InvoicesFilterField.ISSUE_DATE_TO],
    },
  };

  useEffect(() => {
    setSearchParams(
      Object.keys(searchParamsObject).reduce(
        (acc, key) => {
          const value = searchParamsObject[key as keyof typeof searchParamsObject];
          if (value) {
            acc[key] = value;
          }
          return acc;
        },
        {} as Record<string, string>,
      ),
      { replace: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InvoicesTableFiltersContext.Provider value={{ filters, ...formFilterManagersMethods }}>
      {children}
    </InvoicesTableFiltersContext.Provider>
  );
};

export const useInvoicesTableFilterContext = (): IInvoicesTableFiltersContextProps =>
  useContext(InvoicesTableFiltersContext);

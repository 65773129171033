import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { FEATURE_FLAGS } from '@factoring/configs/featureFlags';
import { useInvoiceStatusOptions } from '@factoring/modules/invoice/Invoice.constants';
import { useBranchesAutocompleteListLazyQuery } from '@factoring/types/generated.hooks';
import { Autocomplete } from '@ui/autocomplete';
import { Button } from '@ui/button';
import { Datepicker } from '@ui/datepicker';
import { Flex } from '@ui/flex';
import { Grid } from '@ui/grid';
import { Select } from '@ui/select';
import { CurrencyInput, TextInput } from '@ui/text-input';

import { useInvoicesTableFilterContext } from './InvoicesTableFilters.context';
import { InvoicesFilterField, InvoicesTableFiltersValues } from './InvoicesTableFilters.types';

interface IInvoicesTableFiltersDrawerProps extends UseFormReturn<InvoicesTableFiltersValues> {
  onClose: () => void;
  currency: Currency;
}

export const InvoicesTableFiltersDrawer = ({ onClose, currency, ...methods }: IInvoicesTableFiltersDrawerProps) => {
  const { formatMessage } = useIntl();
  const { searchParams, updateFormFilterValues } = useInvoicesTableFilterContext();
  const statusOptions = useInvoiceStatusOptions();

  const [getBranches, { loading }] = useBranchesAutocompleteListLazyQuery();

  const onSubmit = ({
    status,
    issueDateFrom,
    invoiceNumber,
    issueDateTo,
    issuedToBranchId,
    receivableFrom,
    receivableTo,
    outstandingFrom,
    outstandingTo,
  }: InvoicesTableFiltersValues) => {
    updateFormFilterValues({
      [InvoicesFilterField.ISSUED_TO_BRANCH_ID]: issuedToBranchId?.value,
      [InvoicesFilterField.STATUS]: status?.value,
      [InvoicesFilterField.INVOICE_NUMBER]: invoiceNumber,
      [InvoicesFilterField.ISSUE_DATE_FROM]: issueDateFrom,
      [InvoicesFilterField.ISSUE_DATE_TO]: issueDateTo,
      [InvoicesFilterField.RECEIVABLE_FROM]: receivableFrom,
      [InvoicesFilterField.RECEIVABLE_TO]: receivableTo,
      [InvoicesFilterField.OUTSTANDING_FROM]: outstandingFrom,
      [InvoicesFilterField.OUTSTANDING_TO]: outstandingTo,
    });
    searchParams.delete('offset');
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid gap={24} align="end">
          <Autocomplete
            name={InvoicesFilterField.ISSUED_TO_BRANCH_ID}
            label={<FormattedMessage id="field.issuedTo" />}
            isLoading={loading}
            loadOptions={(inputValue) =>
              getBranches({ variables: { filters: { name: inputValue } } }).then((response) =>
                response.data?.branches.items.map((branch) => ({
                  label: branch.name,
                  value: branch.id,
                })),
              )
            }
            isClearable
          />
          <TextInput
            name={InvoicesFilterField.INVOICE_NUMBER}
            placeholder={formatMessage({ id: 'field.invoiceNumber' })}
            label={formatMessage({ id: 'field.invoiceNumber' })}
          />
          <Grid gap={24} columnGap={16} template={[1, 1]} align="end">
            <Datepicker
              name={InvoicesFilterField.ISSUE_DATE_FROM}
              label={formatMessage({ id: 'field.issueDate' })}
              placeholder={formatMessage({ id: 'field.from' })}
            />
            <Datepicker
              name={InvoicesFilterField.ISSUE_DATE_TO}
              placeholder={formatMessage({ id: 'field.until' })}
              aria-label={formatMessage({ id: 'field.issueDate' })}
            />
            {FEATURE_FLAGS.INVOICES_TABLE_FILTERS_RECEIVABLE && (
              <CurrencyInput
                name={InvoicesFilterField.RECEIVABLE_FROM}
                label={formatMessage({ id: 'field.receivable' })}
                placeholder={formatMessage({ id: 'field.from' })}
                currency={currency}
              />
            )}
            {FEATURE_FLAGS.INVOICES_TABLE_FILTERS_RECEIVABLE && (
              <CurrencyInput
                name={InvoicesFilterField.RECEIVABLE_TO}
                placeholder={formatMessage({ id: 'field.until' })}
                aria-label={formatMessage({ id: 'field.receivableTo' })}
                currency={currency}
              />
            )}
            {FEATURE_FLAGS.INVOICES_TABLE_FILTERS_OUTSTANDING && (
              <CurrencyInput
                name={InvoicesFilterField.OUTSTANDING_FROM}
                label={formatMessage({ id: 'field.outstanding' })}
                placeholder={formatMessage({ id: 'field.from' })}
                currency={currency}
              />
            )}
            {FEATURE_FLAGS.INVOICES_TABLE_FILTERS_OUTSTANDING && (
              <CurrencyInput
                name={InvoicesFilterField.OUTSTANDING_TO}
                placeholder={formatMessage({ id: 'field.until' })}
                aria-label={formatMessage({ id: 'field.outstandingTo' })}
                currency={currency}
              />
            )}
          </Grid>
          <Select
            name={InvoicesFilterField.STATUS}
            options={statusOptions}
            label={formatMessage({ id: 'field.status' })}
          />
          <Flex justify="end">
            <Button type="submit">
              <FormattedMessage id="action.apply" />
            </Button>
          </Flex>
        </Grid>
      </form>
    </FormProvider>
  );
};

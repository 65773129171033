import { Currency, DEFAULT_CURRENCY, FormattedCurrency } from '@bootstrap/utils/formatCurrency';
import { getFontColor } from '@bootstrap/utils/getFontColor';
import { Typography, TypographyType } from '@ui/typography';

export const renderAmount = ({
  amount,
  fontSize = TypographyType.BODY_S,
  currency = DEFAULT_CURRENCY,
  align = 'right',
  color = getFontColor(amount ?? 0),
}: {
  amount?: number | null;
  fontSize?: TypographyType;
  currency?: Currency | null;
  align?: 'left' | 'right' | 'center';
  color?: string;
}): JSX.Element => {
  return (
    <Typography type={fontSize} color={color} align={align}>
      <FormattedCurrency value={amount ?? 0} currency={currency} />
    </Typography>
  );
};

import {
  ColorPositive700,
  ColorNegative700,
  ColorPrimary700,
  ColorNeutral800,
  ColorWarning700,
  ColorWhite,
} from '@hub/design-tokens/colors';
import { Typography, TypographyType } from '@ui/typography';

import { StatusStyles } from './Status.styles';
import { IStatusProps } from './Status.types';

const colors = {
  neutral: ColorNeutral800,
  informative: ColorPrimary700,
  success: ColorPositive700,
  warning: ColorWarning700,
  error: ColorNegative700,
  black: ColorWhite,
  yellow: ColorNeutral800,
} as const;

export const Status = ({ children, icon, type = 'neutral', ...typographyProps }: IStatusProps) => {
  return (
    <StatusStyles type={type}>
      {icon}
      <Typography type={TypographyType.BODY_S} color={colors[type]} as="span" {...typographyProps}>
        {children}
      </Typography>
    </StatusStyles>
  );
};

import { useInvoiceStatusOptions } from '@factoring/modules/invoice/Invoice.constants';
import { InvoiceStatus as InvoiceStatusEnum } from '@factoring/types/generated.enum';
import { Status, StatusType } from '@ui/status';

export const CLIENT_INVOICE_STATUS_TO_STATUS_TYPE: Record<InvoiceStatus, StatusType> = {
  DRAFT: 'neutral',
  SUBMITTED: 'informative',
  DISAPPROVED: 'error',
  ON_HOLD: 'warning',
  OPEN: 'informative',
  CLOSED: 'success',
};

export const InvoiceStatus = ({ status = InvoiceStatusEnum.Draft }: { status: InvoiceStatus }) => {
  const invoiceStatusOptions = useInvoiceStatusOptions();
  const invoiceStatusOption = invoiceStatusOptions.find((option) => option.value === status) || invoiceStatusOptions[0];

  return <Status type={CLIENT_INVOICE_STATUS_TO_STATUS_TYPE[status]}>{invoiceStatusOption.label}</Status>;
};

import { InvoiceStatusOption } from '@factoring/modules/invoice/Invoice.types';
import { SelectOptionType } from '@ui/select';

export enum InvoicesFilterField {
  ISSUED_TO_BRANCH_ID = 'issuedToBranchId',
  INVOICE_NUMBER = 'invoiceNumber',
  ISSUE_DATE_FROM = 'issueDateFrom',
  ISSUE_DATE_TO = 'issueDateTo',
  RECEIVABLE_FROM = 'receivableFrom',
  RECEIVABLE_TO = 'receivableTo',
  OUTSTANDING_FROM = 'outstandingFrom',
  OUTSTANDING_TO = 'outstandingTo',
  STATUS = 'status',
}

export type InvoicesTableFiltersValues = {
  [InvoicesFilterField.ISSUED_TO_BRANCH_ID]: SelectOptionType | null;
  [InvoicesFilterField.INVOICE_NUMBER]: string;
  [InvoicesFilterField.ISSUE_DATE_FROM]: string | null;
  [InvoicesFilterField.ISSUE_DATE_TO]: string | null;
  [InvoicesFilterField.RECEIVABLE_FROM]: string;
  [InvoicesFilterField.RECEIVABLE_TO]: string;
  [InvoicesFilterField.OUTSTANDING_FROM]: string;
  [InvoicesFilterField.OUTSTANDING_TO]: string;
  [InvoicesFilterField.STATUS]: InvoiceStatusOption | null;
};

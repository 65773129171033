import { useIntl } from 'react-intl';

import { InvoiceStatus as InvoiceStatusEnum } from '@factoring/types/generated.enum';
import { ReactComponent as Check } from '@ui/assets/icons/check.svg';
import { BulkActions } from '@ui/bulk-actions';
import { Button } from '@ui/button';

interface ITableBulkWrapperProps {
  onBulkAction: () => void;
  count: number;
  selectedInvoices?: Invoice[];
  isLoading?: boolean;
}

export const InvoicesTableBulkActions = ({
  onBulkAction,
  count,
  selectedInvoices,
  isLoading,
}: ITableBulkWrapperProps) => {
  const { formatMessage } = useIntl();

  const isEveryInvoiceHasDraftStatus = selectedInvoices?.every((invoice) =>
    invoice.status.includes(InvoiceStatusEnum.Draft),
  );

  return (
    <BulkActions>
      <BulkActions.SelectedCount count={count} />
      <Button
        iconLeft={<Check />}
        variant="positive"
        isLoading={isLoading}
        disabled={!count || !isEveryInvoiceHasDraftStatus}
        onClick={onBulkAction}
      >
        {formatMessage({ id: 'action.submit' })}
      </Button>
    </BulkActions>
  );
};
